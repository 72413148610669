<template>
  <cosmetic-allergies-template
    :cosmetic-allergies="cosmeticAllergies"
    @field-change="onFieldChange"
  />
</template>

<script>
import CosmeticAllergiesTemplate from '@/modules/questionnaire/components/steps/common/medical-background/cosmetic-allergies/CosmeticAllergiesTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';

import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { field } = fieldBuilder;

const FIELDS = [field('cosmeticAllergies')];

export default {
  name: 'CosmeticAllergies',
  components: {
    CosmeticAllergiesTemplate
  },
  mixins: [makeStep(FIELDS)]
};
</script>
